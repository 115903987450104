<style>
  .notice-style {
    background: #0065ac;
    color: #FFFFFF;
    font-size: 160%;
  }
</style>

<template>  
  <div class="app-container">

      <!-- Synchronize To CDN Status -->
      <template v-if="syncCDNStatus === APPLY_STATUS_ENABLE">
        <el-card class="box-card">
            <div>
                <el-button type="primary" size="small" @click="onSyncCDN">Synchronize To Remote CDN Server</el-button>
            </div>
        </el-card>
        <br/>
      </template>

      <template v-if="syncCDNStatus === APPLY_STATUS_PROCESSING">
        <el-card class="box-card notice-style">
            <div>
                <span>Synchronize CDN Processing...{{syncCDNProcessingTimeInc}}s</span>
            </div>
        </el-card>
        <br/>
      </template>

      <template v-if="syncCDNStatus === APPLY_STATUS_DONE">
        <el-card class="box-card notice-style">
            <div>
                <span>Synchronization To Remote CDN Server Has Done.</span>
            </div>
        </el-card>
        <br/>
      </template>
      <!-- End Synchronize To CDN Status -->

    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="List Files" name="1">

        <el-table :data="fileData" style="width: 100%">
          <el-table-column label=".No" width="50px">
            <template slot-scope="{row}">
              <span>{{ row.id }}</span>
            </template>
          </el-table-column>

          <el-table-column label="File Name" width="200px">
            <template slot-scope="{row}">
              <span>{{ row.fileName }}</span>
            </template>
          </el-table-column>


          <el-table-column label="Links">
            <template slot-scope="{row}">
              (<a v-bind:href="row.link">Link</a>)
            </template>
          </el-table-column>

        </el-table>
      </el-tab-pane>
      
      <el-tab-pane label="Add File" name="2">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="Bundles" style="width: 50%;">
            <el-upload
              class="upload-demo"
              action=""
              ref="upload"              
              :auto-upload="false"
              :multiple="false"
              :limit="1"
              :on-change="handleChange"
              :on-remove="handleRemove"
              >
              <el-button slot="trigger" size="small" type="primary">Browse</el-button>              
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onAddNewFiles">Add New File</el-button>
          </el-form-item>

        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

var UPLOAD_FOLDER_PATH = "filelist";
var UPLOAD_FILE_GROUP1 = "list1";

// Status for Apply Settings
const APPLY_STATUS_READY = 'READY';
const APPLY_STATUS_ENABLE = 'ENABLE';
const APPLY_STATUS_PROCESSING = 'PROCESSING';
const APPLY_STATUS_DONE = 'DONE';

import setting from '@/settings.js';
import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import {
  createItem,
  getAllItems,
  updateFileData,
  deleteItem
} from "@/api/items";

import {
  UploadFileToRemoteCDN,
  UploadFileStatus,
  GetUploadFileList,
  GetDownloadCDN_URL
} from "@/api/gameservice";

export default {
  name: "Items",
  components: {},
  data() {
    return {
      form: {
        displayName: "",
        codeName: "",
        desc: ""
      },
      fileData: [],
      uploadFileList: [],
      loading: false,
      activeNames: "1",
      //----------------------------------
      syncCDNStatus: '',
      syncCDNProcessingTimeInc: 0,
      isSyncCDNTimerRun: false,
      
      // Apply Settings Statuses
      APPLY_STATUS_READY: APPLY_STATUS_READY,
      APPLY_STATUS_ENABLE: APPLY_STATUS_ENABLE,
      APPLY_STATUS_PROCESSING: APPLY_STATUS_PROCESSING,
      APPLY_STATUS_DONE: APPLY_STATUS_DONE,
      //----------------------------------
    };
  },
  created() {
    this.getListFileItems();
    this.onGetUploadFileStatus();
  },
  methods: {
    getListFileItems() {
      this.loading = true;
      this.fileData = [];

      let promises = [];
      let urlCDN = GetDownloadCDN_URL();
      
      //Message({message: "Get Upload url " + urlCDN, type: "success", duration: 5 * 1000});

      promises.push(this.getUploadFiles (UPLOAD_FILE_GROUP1));

      Promise.all(promises).then(response => {

        this.loading = false;
        let count = 0;
        let errorFile = "";

        var result = response[0];

        if (result.result == 'success')
        {
          let idx = 1;
          result.fileList.forEach(f => 
          {
            let link = urlCDN + "/" + UPLOAD_FOLDER_PATH + "/" + UPLOAD_FILE_GROUP1 + "/" + f;

            this.fileData.push({
              id: idx,
              name: f,
              fileName: f,
              link: link
            });

            idx++;
          });

          //Message({message: "Get Upload file SUCCESS " + JSON.stringify(result), type: "success", duration: 5 * 1000});
        }else
        {
          Message({message: "Get Upload file FAILED " + JSON.stringify(result), type: "error", duration: 5 * 1000});
        }

      }).catch(error => 
      {
        this.loading = false;
        Message({message: "Get Files Failed! " + error, type: "error", duration: 5 * 1000});
      });
    },
    handleClick(obj, e)  {
      if (obj.label === 'List Files')
      {
        this.getListFileItems();
      }

        // refresh upload file status
        this.onGetUploadFileStatus();
    },
    //------------------------------------------------------------------------------------
    // upload one file
    async uploadOneFile(file, fileName, assetGroupName) {

      let result = undefined;
      let filePath = UPLOAD_FOLDER_PATH + "/" + assetGroupName;

      console.log("fileName = " + fileName + "   path = " + filePath);

      await UploadFileToRemoteCDN (
          file.raw,
          fileName,
          filePath
      ).then(response => {
          //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});
          let code = response.data.code;
          if (code === 0)
          {
            result = "success";
          }else
          {
            result = "failed";
          }
        })
        .catch(error => {
          result = "failed";
        });
    
      return result;
    },
    async getUploadFiles(assetGroupName) {

      let result = undefined;
      let filePath = UPLOAD_FOLDER_PATH + "/" + assetGroupName;

      console.log("getUploadFiles: " +  " path = " + filePath);

      await GetUploadFileList (
          filePath
      ).then(response => {
          //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});
          let code = response.data.code;
          if (code === 0)
          {
            result = {
              result: "success",
              fileList: response.data.filelist,
              error: ''
            };
          }else
          {
            result = {
              result: "failed",
              fileList: response.data.filelist,
              error: code
            };
          }
        })
        .catch(error => {
          result = {
              result: "failed",
              fileList: [],
              error: error
            };
        });
    
      return result;
    },
    onGetUploadFileStatus()
    {
      UploadFileStatus("status_polling")
        .then(response => {

          //Message({message: "on Get Upload File Status:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

            let code = response.data.code;
            let message = response.data.message;

            if (message === APPLY_STATUS_PROCESSING ||
                message === APPLY_STATUS_DONE ||
                message === APPLY_STATUS_ENABLE ||
                message === APPLY_STATUS_READY)
            {
              if (message === APPLY_STATUS_READY && this.syncCDNStatus === APPLY_STATUS_PROCESSING)
              {
                message = APPLY_STATUS_DONE;
              }

              if (message === APPLY_STATUS_DONE)
              {
                Message({message: "Synchronize CDN ..." + message, type: "success", duration: 5 * 1000});
              }

              this.syncCDNStatus = message;

              // start a timer to checking status processing next status ... 
              //
              if (this.syncCDNStatus === APPLY_STATUS_PROCESSING)
              {
                // enable loading
                this.loading = true;

                // get information of processing
                let interval = 0;
                
                if (response.data.info)
                {
                  let info = JSON.parse(response.data.info);
                  if (info && info.timestamp)
                  {
                    let timestamp = Math.floor(Date.now()/1000);
                    interval = timestamp - info.timestamp;
                    if (interval < 0)
                    {
                      interval = 0;
                    }
                  }
                }

                this.syncCDNProcessingTimeInc = interval;

                // If message is processing auto recheck status continuely
                if (!this.isSyncCDNTimerRun)
                {
                  this.isSyncCDNTimerRun = true;
                  setTimeout(() =>
                  {
                    this.onGetUploadFileStatus();                    
                    this.isSyncCDNTimerRun = false;
                  }, 5000);    
                }      
              }else{
                  //disable loading
                  this.loading = false;
              }

              //Message({message: "Synchronize CDN ... " + this.syncCDNStatus, type: "success", duration: 5 * 1000});
            }else //other status
            {
              Message({message: "Recheck Status ... Unknown status " + message, type: "error", duration: 5 * 1000});
            }
        });
    },
    onSyncCDN()
    {
      UploadFileStatus("sync_cdn_polling")
        .then(response => {

          //Message({message: "on Sync CDN:" + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          let code = response.data.code;
          if (code === 0)
          {
            Message({message: "[SUCCESS] Synchronize file to remote CDN server", type: "success", duration: 5 * 1000});

            let message = response.data.message;

            this.syncCDNStatus = message;

            // If message is processing auto recheck status continuously
            if (this.syncCDNStatus === APPLY_STATUS_PROCESSING)
            {
              this.syncCDNProcessingTimeInc = 0;
              this.isSyncCDNTimerRun = false;
              this.onGetUploadFileStatus();
            }
          }else
          {
            Message({message: "[FAILED] Synchronize file to remote CDN server", type: "error", duration: 5 * 1000});
          }
        });
    },
    //------------------------------------------------------------------------------------
    // handle Remove and change Browse File
    handleRemove(file, fileList) {        
      this.uploadFileList = fileList;
    },

    //------------------------------------------------------------------------------------
    handleChange(file, fileList) {      
      this.uploadFileList = fileList;
    },
    onAddNewFiles() {

      if (this.uploadFileList.count === 0) {
        Message({
          message: "Please browse a bundle file!",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }


      // default not active event
      let isActivated = 0;
      this.loading = true;
      var nowTime = Date.now();
      let promises = [];

      let file = this.uploadFileList[0];

      let fileName = "unknown";
      if (file.name)
      {
        fileName = file.name;
      }

      promises.push(this.uploadOneFile(file, fileName, UPLOAD_FILE_GROUP1));

      Promise.all(promises).then(response => {

        this.loading = false;
        let count = 0;
        let errorFile = "";

        //Message({message: "Response : " + response, type: "error", duration: 5 * 1000});

        if (response == "success")
        {
          Message({message: " Add file success: " + fileName, type: "success", duration: 5 * 1000});
        }else
        {
          Message({message: " Add file failed : " + fileName, type: "error", duration: 5 * 1000});
        }

        this.onGetUploadFileStatus();

      }).catch(error => 
      {
        this.loading = false;
        Message({message: "Added Failed! " + error, type: "error", duration: 5 * 1000});
      });
    }
    
  }
};
</script>